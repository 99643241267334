import {Component, Provide, Vue, Watch} from 'vue-property-decorator';
import common from '../Common/Index.vue'
import { PhoneLoginReq, VerificationCodeReq } from "@/interface/res/login";
import { phone } from "@/utils/regExp";
import { PhoneLoginApi, SmsPhoneLoginApi } from "@/network/modules/login";
import {mapGetters} from "vuex";
import PageRouter from "@/router/PageRouter";
import {RouteItem} from "@/interface/pagas/login";

@Component({
  components: {
    common
  },
  computed: {
    ...mapGetters(["userInfo"])
  }
})
export default class Code extends Vue {
  @Provide() form:PhoneLoginReq = {
    phone:"",
    device:"PC",
    phoneCode:"",
    platformType:1,
  };
  @Provide() phoneMsg: string = ''
  @Provide() phoneCodeMsg: string = ''
  @Provide() msgType: string = 'err'
  @Provide() codeNum: number = 60
  @Provide() loading: boolean = false
  @Provide() codeLoading: boolean = false

  @Provide() routeLink:string = ''
  @Provide() routeItem:RouteItem = {name:"",config:"",key:"",}

  @Provide() newWindow:any = null

  @Watch('userInfo')
  private iDataChange():void {
    const _this:any = this;
    if(_this.$base.isNull(localStorage.getItem('_TOKEN')))return
    if(_this.$base.isNull(_this.routeLink)){
      _this.$base.goRouter(_this.$IndexConfig.home.path)
      return;
    }
    let code = _this.$route.query
    for (let codeKey in code) {
      code[codeKey] = _this.$decryptBy(code[codeKey])
    }
    delete code.r
    let codeStr = _this.$base.serializeStr(code)
    if(_this.routeItem.config == 'IndexConfig'){
      const link = _this.$base.routeLinkSplice(_this.$IndexConfig[_this.routeItem.key].path,codeStr)
      _this.$base.goRouter(link)
      return;
    }
    if(_this.routeItem.config == 'LoginConfig'){
      const link = _this.$base.routeLinkSplice(_this.$LoginConfig[_this.routeItem.key].path,codeStr)
      _this.$base.goRouter(link)
      return;
    }
    if(_this.routeItem.config == 'MineConfig'){
      const link = _this.$base.routeLinkSplice(_this.$MineConfig[_this.routeItem.key].path,codeStr)
      _this.$base.goRouter(link)
      return;
    }
  }

  created(){
    const _this:any = this;
    if(!_this.$base.isNull(_this.$route.query.r)){
      _this.routeLink = _this.$decryptBy(_this.$route.query.r)
      _this.routeItem = PageRouter.filter((item:any) => item.key == _this.routeLink)[0]
    }
  }

  /**
   * @Author HS
   * @Date 2021/8/26 9:51 上午
   * @Description: 微信登录
   * @Params: null
   * @Return: null
   */
  wechatLoginFn(){
    const _this:any = this;
    let url = `${process.env.VUE_APP_BASE_URL}/shop/account/oauth/login/web?source=wechat_open&route=`
    if (this.$route.query.r) {
      url += _this.$base.serializeStrTow(_this.$route.query)
    }
    _this.newWindow = window.open(url, "_blank");
  }

  /**
   * @Author HS
   * @Date 2021/7/2 9:46 上午
   * @Description: 验证码登录
   * @Params: null
   * @Return: null
  */
  async loginFn(){
    const _this:any = this;

    if(!phone.test(_this.form.phone)){
      _this.phoneMsg = "请输入正确的手机号码"
      return
    }
    _this.phoneMsg = ""

    if(_this.$base.isNull(_this.form.phoneCode)){
      _this.msgType = 'err'
      _this.phoneCodeMsg = "请输入验证码"
      return
    }
    _this.phoneCodeMsg = ""

    _this.loading = true
    let params:PhoneLoginReq = {..._this.form}
    const res = await PhoneLoginApi(params)
    let a = setTimeout(()=>{_this.loading = false;clearTimeout(a)},500)
    const { data, code , msg } = res
    if(code != 200 ){
      _this.msgType = 'err'
      _this.phoneCodeMsg = msg
      return
    }
    _this.phoneCodeMsg = ""

    // localStorage.setItem("_TOKEN",data.tokenValue)
    // _this.$store.dispatch('GetInfoFn',_this)

    _this.$store.commit('SET_TOKEN',data.tokenValue)
  }

  /**
   * @Author HS
   * @Date 2021/6/29 2:19 下午
   * @Description: 页面跳转
   * @Params: { string ： path - true } [path：路径]
   * @Return: null
   */
  goRouter(path:string){
    const _this:any = this;
    let code = _this.$route.query
    for (let codeKey in code) {
      code[codeKey] = _this.$decryptBy(code[codeKey])
    }
    let codeStr = _this.$base.serializeStr(code)
    const link = _this.$base.routeLinkSplice(_this.$LoginConfig[path].path,codeStr)
    _this.$base.goRouter(link)
  }

  /**
   * @Author HS
   * @Date 2021/6/29 3:35 下午
   * @Description: 获取验证码
   * @Params: null
   * @Return: null
  */
  async getVerificationCodeFn(){
    const _this:any = this;
    if(_this.codeLoading)return

    if(!phone.test(_this.form.phone)){
      _this.phoneMsg = "请输入正确的手机号码"
      return
    }
    _this.phoneMsg = ""

    _this.codeLoading = true
    let params:VerificationCodeReq = {
      phone:_this.form.phone,
      platformType:1
    }
    const res = await SmsPhoneLoginApi(params)
    let a = setTimeout(()=>{_this.codeLoading = false;clearTimeout(a)},500)
    const { code , msg } = res
    if(code != 200 ){
      _this.phoneMsg = msg
      return
    }

    _this.phoneMsg = ""
    _this.codeNum = 59
    _this.countdownFn()
  }

  /**
   * @Author HS
   * @Date 2021/6/29 3:17 下午
   * @Description: 倒计时
   * @Params: null
   * @Return: null
  */
  countdownFn(){
    const _this:any = this
    let time = setTimeout(()=>{
      _this.codeNum--

      if(_this.codeNum > 0){
        clearTimeout(time)
        _this.countdownFn()
        return
      }
      _this.codeNum = 60
      clearTimeout(time)
    }, 1000);
  }

}
